import * as Yup from "yup";

// INITIAL VALUES

export const productInitialValues = {
  sku: "",
  name: "",
  categoryId: "",
  subCategoryId: null,
  description: "",
  additionalInformation: "",
  type: "B2C",
  brand: "",
  sections: [],
  occasion: [],
  fabric: [],
  length: "",
  pattern: "",
  color: [],
  tags: [],
  isSize: false,
  sizeType: [],
  // mainImage: "",
  originalPrice: 0,
  sellingPrice: 0,
  discount: 0,
  discountLabel: "",
  b2bPrice: {},
  // images: [],
  shopId: import.meta.env.VITE_SHOP_ID || "",
  shop: {
    name: import.meta.env.VITE_SHOP_NAME || "",
    location: import.meta.env.VITE_SHOP_LOCATION || "",
  },
  keywords: [],
  metaTitle: "",
  metaDescription: "",
  stock: [],
};

export const categoryInitialValues = {
  name: "",
  image: "",
  description: "",
};

export const staticPageInitialValues = {
  title: "",
  slug: "",
  description: "",
};

export const generalSettingInitialValues = {
  homePageBanner: [],
  address: "",
  email: "",
  contact: "",
  googleApiKey: "",
  googleLat: "",
  googleLong: "",
  androidForceUpdate: false,
  iosForceUpdate: false,
  maintenance: false,
  androidAppVersion: "1.0.0",
  iosAppVersion: "1.0.0",
  socialLinks: {},
  commonMetaTags: [],
  metaTitle: "",
  metaDescription: "",
  advertisementBanner: []
};

export const adminProfileInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: "+91",
  contactNumber: "",
};

export const couponInitialValues = {
  code: "",
  description: "",
  discountType: "",
  discountValue: 0,
  maxDiscount: 0,
  expiry: "",
  maxUses: 10000,
  minCartValue: 0,
  status: "",
  bundleQuantity: 0,
  bundlePrice: 0,
  bundleCategory: "",
};

export const subAdminInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  countryCode: "",
  contactNumber: "",
};

// VALIDATION SCHEMA

export const categoryValidationSchema = Yup.object().shape({
  name: Yup.string().required("Color Name is required"),
  image: Yup.string().required("Image is required"),
  description: Yup.string().required("Description is required"),
});

export const productValidationSchema = Yup.object().shape({
  sku: Yup.string().required("SKU is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  additionalInformation: Yup.string().required(
    "Additional Information is required"
  ),
  categoryId: Yup.string().required("Category is required"),
  brand: Yup.string().required("Brand is required"),
  // mainImage: Yup.string().required("Main Image is required"),
  stock: Yup.array().min(1, "Stock is required"),
  color: Yup.array().min(1, "Color is required"),
  originalPrice: Yup.number().min(1).required("Original Price is required"),
  sellingPrice: Yup.number().min(1).required("Selling Price is required"),
  discount: Yup.number().min(1).required("Discount is required"),
});

export const staticPageValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  slug: Yup.string().required("Slug is required"),
  description: Yup.string().required("Description is required"),
});

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Firstname is required"),
  lastName: Yup.string().required("Lastname is required"),
  email: Yup.string().required("Email is required"),
  contactNumber: Yup.string().required("Contact Number is required"),
});

export const couponValidationSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
  description: Yup.string().required("Description is required"),
  discountType: Yup.string().required("Discount Type is required"),
  discountValue: Yup.number().when("discountType", {
    is: (val) => val === "flat" || val === "percentage",
    then: Yup.number()
      .required("Discount Value is required")
      .min(0, "Discount Value must be at least 0"),
    otherwise: Yup.number().min(0, "Discount Value must be at least 0"),
  }),
  maxDiscount: Yup.number().when("discountType", {
    is: "percentage",
    then: Yup.number()
      .required("Max Discount is required")
      .min(0, "Max Discount must be at least 0"),
    otherwise: Yup.number().min(0, "Max Discount must be at least 0"),
  }),
  bundleQuantity: Yup.number().when("discountType", {
    is: "bundle",
    then: Yup.number()
      .required("Quantity is required")
      .min(0, "Quantity must be at least 0"),
    otherwise: Yup.number().min(0, "Quantity must be at least 0"),
  }),
  bundlePrice: Yup.number().when("discountType", {
    is: "bundle",
    then: Yup.number()
      .required("Price is required")
      .min(0, "Price must be at least 0"),
    otherwise: Yup.number().min(0, "Price must be at least 0"),
  }),
  bundleCategory: Yup.string().when("discountType", {
    is: "bundle",
    then: Yup.string().required("Category is required"),
  }),
  expiry: Yup.date()
    .required("Expiry date is required")
    .min(new Date(), "Expiry date must be in the future"),
  maxUses: Yup.number()
    .required("Max Uses is required")
    .min(0, "Max Uses must be at least 0"),
  minCartValue: Yup.number().when("discountType", {
    is: (val) => val === "flat" || val === "percentage",
    then: Yup.number()
      .required("Minimum Cart Value is required")
      .min(0, "Minimum Cart Value must be at least 0"),
    otherwise: Yup.number().min(0, "Minimum Cart Value must be at least 0"),
  }),
  status: Yup.string().required("Status is required"),
});

export const subAdminValidationSchema = (subAdminId) => Yup.object().shape({
  firstName: Yup.string().required("Firstname is required"),
  lastName: Yup.string().required("Lastname is required"),
  email: Yup.string().required("Email is required"),
  password: subAdminId ? Yup.string().optional() : Yup.string().required("Password is required"),
  countryCode: Yup.string().required("Country Code is required"),
  contactNumber: Yup.string().required("Contact Number is required"),
});